import makeStyles from '@material-ui/core/styles/makeStyles';

const hoverBg = '#e5e6e8';
const hoverColor = '#686b7a';
const thirdLevelActiveBg = '#e8f7fc';
const thirdLevelActiveColor = '#040921';
const activeColor = '#1bace0';
const defaultColor = '#aeb0b8';

export default makeStyles((theme) => ({
  fixedPlaceholder: {
    height: '68px',
  },
  topMenu: {
    width: '100%',
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
  },
  inner: {
    backgroundColor: '#fafafb',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'visible',
    WebkitOverflowScrolling: 'touch',
  },
  menuGroup: {
    display: 'flex',
    listStyle: 'none',
    paddingLeft: '0',
    margin: '0',
  },
  rootMenuItem: {
    cursor: 'pointer',
    flex: 1,
    maxWidth: '105px',
    minWidth: '100px',
    color: defaultColor,
    '& > a': {
      color: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(1.5)}px 0px`,
      textDecoration: 'none',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: '.75rem',
      '& svg': {
        width: '24px',
        marginBottom: `${theme.spacing(0.75)}px`,
      },
    },
    '& > ul': {
      visibility: 'hidden',
      pointerEvent: 'none',
      transition: 'visibility .0s',
      transitionDelay: '0.1s',
    },
    '&:hover': {
      backgroundColor: hoverBg,
      color: hoverColor,
      '& > ul': {
        visibility: 'visible',
        pointerEvent: 'auto',
        transitionDelay: '0s',
      },
    },
  },
  rootMenuItemActive: {
    '&, &:hover': {
      backgroundColor: hoverBg,
      color: activeColor,
    },
  },
  subMenuGroup: {
    listStyle: 'none',
    paddingLeft: '0',
    backgroundColor: '#fff',
    minWidth: '180px',
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
    border: `1px solid ${hoverBg}`,
    transform: 'translateX(var(--scrollLeft))',
  },
  subMenuItem: {
    color: defaultColor,
    fontWeight: 'bold',
    fontSize: '.75rem',
    whiteSpace: 'nowrap',
    position: 'relative',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px ${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${hoverBg}`,
      '&:hover': {
        color: hoverColor,
      },
    },
  },
  collapseIcon: {
    margin: '0 -.5em 0 0',
  },
  subMenuItemActive: {
    '& > a': {
      borderRight: `4px solid ${activeColor}`,
      color: activeColor,
    },
  },
  thirdLevelMenuGroup: {
    listStyle: 'none',
    paddingLeft: '0',
  },
  thirdLevelMenuItem: {
    '& a': {
      paddingLeft: `${theme.spacing(4)}px`,
      paddingRight: `${theme.spacing(3)}px`,
      fontWeight: 'normal',
    },
  },
  thirdLevelMenuItemActive: {
    '& a': {
      backgroundColor: thirdLevelActiveBg,
      color: thirdLevelActiveColor,
    },
  },
}));
